<template>
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false"        @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
           
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0"> {{ module_name }}</h3> 
                    </div>
                    <Divider class="mt-0" />
                    <TabView  @tab-click="loadTargetedTab($event)" class="tabview-custom" v-model:activeIndex="activeTab" :scrollable="true">
                         <TabPanel header="Student Information">
                            <template #header>
                                &nbsp; <i class="pi pi-info-circle"></i>
                            </template>
<div class="grid" style="font-size:16px;">
    <div class="col-12 md:col-6">
        <div class="card height-100 widget-topsearchs">
            <div class="card-header">
                <h3>Leave Information</h3>
            </div>
            <div class="flex justify-content-between item"><span>From DateTime: </span><span
                    class="value type-green">{{ (data.leave_start_date) }}</span></div>
            <div class="flex justify-content-between item">
                <span>To DateTime:</span>
                <InputText type="datetime-local" id="date_time" v-model="leaveEndDate" :min="minDateTime" />
            </div>
            <div class="flex justify-content-between item"><span>Date Applied:</span><span
                    class="value type-green">{{ (data.date_added) }}</span></div>

            <div class="flex justify-content-between item"><span>Type:</span><span
                    class="value type-yellow">{{ data.leave_type }}</span></div>
            <div class="flex justify-content-between item"><span>Status:</span><span class="value type-yellow">
                    <Tag v-if="data.leave_status == 0" :class="'mr-2 p-2 fs-large status-Active'"
                        icon="pi pi-info-circle" :severity="'warning'" :value="'Pending'"></Tag>
                    <Tag v-if="data.leave_status == 1" :class="'mr-2 p-2 fs-large status-Active'"
                        icon="pi pi-check-circle" :severity="'success'" :value="'Approved'"></Tag>
                    <Tag v-if="data.leave_status == 2" :class="'mr-2 p-2 fs-large status-Blocked'"
                        icon="pi pi-times-circle" :severity="'danger'" :value="'Rejected'"></Tag>
                </span></div>
            <div class="flex justify-content-between item"><span>File:</span><span class="value type-pink">
                    <span v-for="( file, index ) in  files " :key="index">
                        <Tag v-if="file.file_name" @click="downloadFile(file.file_name)" :class="'mr-2 status-Active'"
                            icon="pi pi-eye" :severity="'success'" :value="'View File'" style="cursor:pointer;"></Tag>
                    </span>
                    <!-- <Tag v-else :class="'mr-2 status-Blocked'" icon="pi pi-times" :severity="'danger'" :value="'No File'"></Tag> -->
                </span>
            </div>
            <div class=" item">
                <b><span>Description:</span></b><br />
                <span class="value type-green">{{ htmlparser_data(data.leave_application) }}</span>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-6">
        <div class="card height-100 widget-topsearchs">
            <div class="card-header">
                <div>
                    <img v-if="data.profile_pic" :alt="data.name"
                        :src="studentFiles + 'students/' + data.std_id + '/' + data.profile_pic"
                        style="width:80px; height: 80px;" />
                    <img v-if="!data.profile_pic" :alt="data.name" :src="filesUrl + 'defaults/default.png'"
                        style="width:80px; height: 80px;" />
                </div>
                <div>
                    <h3>{{ data.name }} - {{ data.student_id }}</h3>
                    {{ data.class }}<br />
                    Room No: {{ data.room_no }}
                </div>
            </div>
            <div class="flex justify-content-between item">
                <span>Email: </span><span class="value type-green">{{ data.email }}</span>
            </div>
            <div class="flex justify-content-between item">
                <span>Mobile: </span><span class="value type-green">{{ data.mobile_1 }}</span>
            </div>
            <div class="flex justify-content-between item">
                <span>Roll-No: </span><span
                    class="value type-green">{{ data.system_rollno_prefix + data.system_rollno }}</span>
            </div>
            <div class="flex justify-content-between item">
                <span>Father Name: </span><span class="value type-green">{{ data.father_name }}</span>
            </div>
            <div class="flex justify-content-between item">
                <span>Father Mobile: </span><span class="value type-green">{{ data.father_mobile_no }}</span>
            </div>
            <div class="flex justify-content-between item">
                <span>Father/Guardian Email: </span><span
                    class="value type-green">{{ data.fath_email ? data.fath_email : data.guardian_email }}</span>
            </div>
        </div>
    </div>
</div>
</TabPanel>
<TabPanel header="Leave History">
    <template #header>
                                &nbsp; <i class="pi pi-info-circle"></i>
                            </template>
    <div class="grid" style="font-size:16px;">

        <div class="col-12 md:col-12">
            <div class="card height-100">
                <div class="card-header">
                    <h5>History</h5>
                </div>
                <DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="leaveHistory"
                    :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm" :rows="10"
                    dataKey="id" :rowHover="true" v-model:filters="filters" :loading="loading" :filters="filters"
                    responsiveLayout="scroll"
                    :globalFilterFields="['app_id', 'class', 'student_id', 'name', 'email', 'mobile_1', 'leave_type', 'father_name', 'fath_email', 'father_mobile_no', 'leave_start_date', 'leave_end_date', 'in_date', 'out_date', 'date_added', 'cnic']"
                    :rowsPerPageOptions="[10, 20, 50, 100]"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    v-model:selection="selectedRecords" selectionMode="multiple" :scrollable="true"
                    scrollHeight="400px">

                    <template #header>
                                            <div class="flex justify-content-between flex-column sm:flex-row">  
                                                <span class="p-input-icon-left mb-2">
                                                    <i class="pi pi-search" />
                                                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                                                </span>
                                                <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                                    placeholder="Select Columns" style="width: 20em"/>
                                            </div>
                                        </template>

                    <template #empty>
                                            <div class="table-message"> 
                                                <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                                            </div>
                                        </template>
                    <template #loading>
                                            Loading data. Please wait.
                                        </template>

                    <Column v-for="( col, index ) of  selectedColumns " :field="col.field" :header="col.header"
                        :exportable="col.field == 'action' ? false : true"
                        :sortable="col.field == 'action' ? false : true" :key="col.field + '_' + index"
                        :class="col.field == 'action' ? 'btnAction' : ''"
                        :style="col.field !== 'app_id' ? 'min-width:22rem;flex-grow:1; flex-basis:160px;' : 'width:10rem;flex-grow:1; flex-basis:160px;'">

                        <template #body="{ data }">
                                                <span v-if="col.field == 'app_id'">{{ data.app_id }}</span>
                                                <span v-if="col.field == 'leave_start_date'">
                                                 <b>Start DateTime:</b>   {{ (data.leave_start_date) }}<br/>
                                                 <b>End DateTime:</b>   {{ (data.leave_end_date) }}
                                                </span>
                                                <!-- <span v-if="col.field == 'leave_end_date'">{{ (data.leave_end_date) }}</span> -->
                                                <span v-if="col.field == 'out_date'">
                                                 <b>Out DateTime</b>   {{ data.out_date ? (data.out_date) : 'N/A' }}<br/>
                                                 <b>IN DateTime</b>   {{ data.in_date ? (data.in_date) : 'N/A' }}
                                                </span>
                                                <!-- <span v-if="col.field == 'in_date'">{{ data.in_date ? (data.in_date):null }}</span> -->
                                                <span v-if="col.field == 'leave_type'">
                                                    <b>Leave Type:</b><Tag v-if="data.leave_status == 1" :class="'mr-2 status-Active'" :severity="'success'" :value="data.leave_type"></Tag><br />
                                                    <b>Date Applied:</b>{{ (data.date_added) }}
                                                </span>
                                                <!-- <span v-if="col.field == 'date_added'">{{ (data.date_added) }}</span> -->

                                                <span v-if="col.field == 'leave_status'">
                                                    <Tag v-if="data.leave_status == 0" :class="'mr-2 status-Active'" icon="pi pi-info-circle" :severity="'warning'" :value="'Pending'"></Tag>
                                                    <Tag v-if="data.leave_status == 1" :class="'mr-2 status-Active'" icon="pi pi-check-circle" :severity="'success'" :value="'Approved'"></Tag>
                                                    <Tag v-if="data.leave_status == 2" :class="'mr-2 status-Blocked'" icon="pi pi-times-circle" :severity="'danger'" :value="'Rejected'"></Tag>
                                                </span>
                                               
                                                <span v-if="col.field == 'leave_application'">{{ shortStr(htmlparser_data(data.leave_application)) }}</span>
                                            </template>
                        <template #filter="{ filterModel }">
                                                <span v-if="col.field == 'app_id'"> 
                                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Application Id"/>
                                                </span>
                                                <!-- <span v-if="col.field == 'name'"> 
                                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Name"/>
                                                </span> 
                                                <span v-if="col.field == 'class'"> 
                                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by class"/>
                                                </span> 
                                                <span v-if="col.field == 'leave_start_date'"> 
                                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by From Date"/>
                                                </span> 
                                                <span v-if="col.field == 'leave_end_date'"> 
                                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by To Date"/>
                                                </span> 
                                                <span v-if="col.field == 'out_date'"> 
                                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Out Date"/>
                                                </span> 
                                                <span v-if="col.field == 'in_date'"> 
                                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by In Date"/>
                                                </span>   
                                                <span v-if="col.field == 'date_added'">
                                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Date Added"/>
                                                </span>
                                                <span v-if="col.field == 'father_name'"> 
                                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by father_name"/>
                                                </span> 
                                                <span v-if="col.field == 'gender'"> 
                                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by gender"/>
                                                </span> 
                                                <span v-if="col.field == 'cnic'"> 
                                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by cnic"/>
                                                </span> 
                                                <span v-if="col.field == 'leave_type'"> 
                                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by leave_type"/>
                                                </span> -->
                                            </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</TabPanel>
</TabView>

<Divider />
<div class="col-12 lg:col-12">
    <ProgressSpinner v-if="loading" style="width:50px;height:50px" strokeWidth="8" fill="var(--surface-ground)"
        animationDuration=".5s" aria-label="Custom ProgressSpinner" />

    <Toolbar class="pl-0 pr-0">

        <template v-slot:start v-if="!loading && data.leave_status == 0">
                                <Button type="submit" @click="leaveStatus(data.app_id, 'approve')" label="Approve"  icon="pi pi-check" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="button" @click="leaveStatus(data.app_id, 'reject')" label="Reject" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-danger mr-2"/>
                            </template>
        <template v-slot:end>
                                <Button type="button" label="Save" icon="pi pi-check" class="p-right p-button-raised p-button-lg p-button-success mr-2" @click="updateLeaveEndDate()"/>
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template>
    </Toolbar>
</div>
</div>

</Sidebar>
</BlockUI>
<ConfirmDialog group="dialog" />
</template>
<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
    data() {
        return {
            module_name: 'Leave Application',
            isSaving: false,
            actions: [],
            saveAndClose: false,
            recId: 0,
            loadingComponent: true,
            formVisible: false,
            data: [],
            roomHistory: [],
            columns: null,
            selectedColumns: null,
            filters: null,
            loading: true,
            leaveHistory: [],
            files: [],
            leaveEndDate: '',
            minDateTime: ''
        }
    },
    props: {
        showEditView: {
            type: Boolean,
            required: true,
        },
        recordId: {
            type: Number,
        },
    },
    created() {

        this.initFilters();
        this.columns = [
            { field: 'app_id', header: 'Application ID' },
            { field: 'leave_start_date', header: 'Leave DateTime' },
            // {field: 'leave_end_date', header: 'To DateTime'}, 
            { field: 'out_date', header: 'Out/IN DateTime' },
            // {field: 'in_date', header: 'In  DateTime'}, 
            { field: 'leave_type', header: 'Leave Info' },
            { field: 'leave_status', header: 'Leave Status' },
            // {field: 'date_added', header: 'Date Applied'},     
            { field: 'leave_application', header: 'Description' },


        ];
        this.selectedColumns = this.columns;
    },
    computed: {

    },
    mounted() {
        this.formVisible = this.showEditView;
        this.recId = this.recordId;
        this.viewRecord();
        this.minDateTime = this.getCurrentDateTime();
    },
    methods: {
        formatDateForInput(dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hour = String(date.getHours()).padStart(2, '0');
            const minute = String(date.getMinutes()).padStart(2, '0');
            return `${year}-${month}-${day}T${hour}:${minute}`;
        },
        getCurrentDateTime() {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hour = String(now.getHours()).padStart(2, '0');
            const minute = String(now.getMinutes()).padStart(2, '0');

            return `${year}-${month}-${day}T${hour}:${minute}`;
        },
        loadTargetedTab($event) {
            this.showTab1 = false;
            this.showTab2 = false;
            let i = 1;
            do {
                if ($event.index === 1) {
                    this.showTab1 = true;
                }
                if ($event.index === 2) {
                    this.showTab2 = true;
                }
                i += 1;
            } while (i < 6);
        },
        closeForm() {
            this.$emit('formClose', true);
        },
        async updateLeaveEndDate() {
            var Vthis = this;
            this.loading = true;
            await this.axios.post('updateLeaveEndDate', { id: this.recId, date: this.leaveEndDate })
                .then((response) => {
                    if (response.data.msg == 1) {
                        Vthis.showMessage('Leave Date Extended Successfully', 'success');
                    } else {
                        Vthis.showMessage('Leave Date is not Extended', 'error');
                    }

                }).catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                }).finally(() => {
                    this.loading = false;
                })
        },
        async viewRecord() {
            var Vthis = this;
            this.loading = true;
            await this.axios.post('getAllHostelApplications', { id: this.recId })
                .then((response) => {
                    this.leaveHistory = response.data.data;
                    this.data = response.data.data[0];
                    this.files = response.data.files;

                    this.leaveEndDate = this.formatDateForInput(this.data.input_leave_end_date);
                    this.getLeaveHistory(this.data.std_id);
                }).catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                }).finally(() => {
                    this.loading = false;
                })
        },
        async getLeaveHistory(stdId) {
            var Vthis = this;
            this.loading = true;
            await this.axios.post('getAllHostelApplications', { std_id: stdId })
                .then((response) => {
                    this.leaveHistory = response.data.data;
                }).catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                }).finally(() => {
                    this.loading = false;
                })
        },
        clearFilter() {
            this.initFilters();
        },
        onToggle(value) {
            this.selectedColumns = this.columns.filter(col => value.includes(col));
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'app_id': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'class': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'leave_start_date': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'leave_end_date': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'date_added': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'out_date': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'in_date': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'leave_type': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'gender': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'father_name': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'cnic': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            }
        },
        leaveStatus(app_id, status) {
            var Vthis = this;
            var leave_status;
            if (status == 'approve') {
                leave_status = 1;
            } else { leave_status = 2; }
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirmation',
                message: 'Are you sure you want to change status ?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    Vthis.loading = true;
                    Vthis.axios.post('changeAppStatus', { id: app_id, leave_status: leave_status })
                        .then(() => {
                            Vthis.showMessage('Status Changed successfully', 'success');
                        }).catch((error) => {
                            let message = Vthis.customError(error.response);
                            Vthis.showMessage(message, 'error');
                        }).finally(() => {
                            Vthis.loading = false;
                            this.viewRecord();
                            this.emitter.emit('Reload', true);

                        })
                }, reject: () => {
                    // this.loading = false;
                }
            });
        }
    },
    watch: {

    }
}

</script>
